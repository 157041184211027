.App-nav {
  margin-top: 10vw;
}

.Grid {
  width: 60vw;
  max-width: 2000px;
  margin: 2vw 20vw;
}


.Cell {
  text-align: center;
  background-color: #94fdfd;
  float: left;
  margin: min(0.5vw, 15px);
  width: 14vw;
  height: 14vw;
  font-size: min(10vw, 310px);
  max-height: 434px;
  max-width: 434px;
  user-select: none;
  -webkit-user-select: none; /*Safari*/
  -moz-user-select: none; /*Firefox*/
}
